// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-issues-issues-bar-chart-tsx": () => import("./../../../src/pages/issues/issues-bar-chart.tsx" /* webpackChunkName: "component---src-pages-issues-issues-bar-chart-tsx" */),
  "component---src-pages-issues-issues-by-state-index-tsx": () => import("./../../../src/pages/issues/issues-by-state/index.tsx" /* webpackChunkName: "component---src-pages-issues-issues-by-state-index-tsx" */),
  "component---src-pages-issues-scatterplot-tsx": () => import("./../../../src/pages/issues/scatterplot.tsx" /* webpackChunkName: "component---src-pages-issues-scatterplot-tsx" */),
  "component---src-pages-states-all-tsx": () => import("./../../../src/pages/states/all.tsx" /* webpackChunkName: "component---src-pages-states-all-tsx" */)
}

